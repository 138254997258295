import React from 'react';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import { BaseP, HeaderMedium } from 'components/typography';
import { mainContainerWidth } from 'common/styledVars';
import { RelativeWrapper, WidthWrapper } from 'components/wrappers';
import EcommerceBrandsSlider from 'components/Carousel/ecommerce-brands-slider'

const SliderHeader = styled.h2`
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 0;
  text-align: center;

  @media only screen and (min-width: 1025px) {
    font-size: 36px;
    margin-bottom: 25px;
  }
`;

const Container = styled.div`
    max-width: 1050px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
`;

const MainWrapper = styled(RelativeWrapper)`
  padding-top: 0;
  padding-bottom: 0;

  @media only screen and (min-width: 1025px) {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  
`;

const EcommerceBrands = () => {
  const intl = useIntl()
  return (
    <MainWrapper>
        <Container>
            <div className="section" id="ecommerce-brands">
                <SliderHeader>
                  {intl.formatMessage({ id: "home-global-ecommerce-brand-title" })}
                </SliderHeader>
                <EcommerceBrandsSlider />
            </div>
        </Container>
    </MainWrapper>
  );
};

export default EcommerceBrands;
