import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import {
  warehousingCompanies,
  couriersCompanies,
  otherCompanies,
} from 'components/pages/Partners/companies';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';

export const DetailContent = styled.div`
  ${HeaderMedium} {
    margin-top: 40px;
    margin-bottom: 80px;
  }
`;

export const PageContainer = styled.div`
  margin-bottom:0;
  width:100%;

  .column-whole {
    position:relative;
  }

  .column-half {
    width: 48%;
    display: inline-block;
    float: left;
    padding-right:4%;
  }

  .column-half.last {
    width: 48%;
    padding-right:0;
  }

  .column-third {
    width:31%;
    display:inline-block;
    margin-right:3%;
    float:left;
  }

  .column-third.last {
    margin-right:0;
  }

  .column-fourth {
    width:25%;
    display:inline-block;
    padding-left:1%;
    padding-right: 1%;
    float:left;
  }

  /*.column-fourth.last {
    padding-right:0;
  }*/

  .service-container {
    padding: 20px 20px 30px 20px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    background: #ffffff;
  }

  .service-container img {
    margin-left: auto;
    margin-right: auto;
    min-height: 100px;
    max-height: 100px;
  }

  .service-title {
    text-align: center;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .service-desc {
    font-size: 15px;
    margin-bottom: 15px;
  }

  #services-row {
    padding-bottom: 40px;
    margin-top: -120px;
  }

  .service-desc-container {
    min-height: 265px;
  }

  .service-btn-container {
    text-align: center;
  }

  .service-btn-container button {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  #contact-us-section {
    padding-bottom: 50px;
    padding-top: 30px;
  }

  form {
    margin-top: 0px !important;
    margin-bottom: 20px !important;
    max-width: 700px;
  }

  form div {
    margin-bottom: 20px;
  }

  form > div {
    margin-left: 30px;
  }

  form div div {
    margin-bottom: 0;
  }

  form button {
    margin-top: 0 !important;
  }
  form textarea {
    margin-bottom: -30px;
  }

  form input, form textarea {
    border: 1px solid rgb(0, 175, 133);
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    opacity: 1;
  }

  .contact-form-description {
    color: #7f7f7f;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .contact-form-header {
    font-size: 25px;
    color: #00af85;
    margin-bottom: 15px;
  }

  .contact-form-img-container {
    margin-top: 70px;
  }

`;

export const MainSection = styled.div`
  position:relative;

  #hero-section {
    padding:0;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 70px;
  }

  #hero-section .column-half {
    width: 50%;
    padding-right: 0;
    position: relative;
    padding-top: 70px;
    padding-bottom: 80px;
    padding-left: 8%;
    display: inline-block;
    float: left;
  }

  #hero-section .column-whole {
    padding: 120px 0px 135px 0px;
    max-width:900px;
    margin-left:auto;
    margin-right:auto;
  }

  #hero-section .content-container {
    position: relative;
    z-index: 2;
  }

  #hero-section .column-half p{
    padding-right:60px;
  }

  #hero-section .column-half.last {
    width: 50%;
    padding-right: 0;
    position: relative;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    float: left;
  }

  #hero-section .column-half img{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-top:50px;
    margin-bottom:50px;
  }

  #hero-section .text-white {
    color: #ffffff
  }

  .hero-bg {
    position: absolute !important;
    top: 0;
    left:0;
    /*object-fit: cover;*/
    right:0;
    height: 100%;
    width: 100%;
    bottom: 0;
    float:left;
  }

  #hero-section .hero-bg-tablet {
    display:none;
  }

  #hero-section .hero-bg-mobile {
    display:none;
  }

  #services-row.ja  .service-title {
    min-height: 46px;
  }

  

  @media only screen and (max-width:1130px) {
    .service-btn-container button {
      min-width: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width:1212px) {
    .column-fourth {
      width: 50%;
      margin-bottom: 35px;
    }

    .service-desc-container {
      min-height: 130px;
    }
  }

  @media only screen and (max-width:1025px) {

    #hero-section .hero-bg {
      object-fit: cover;
    }

    .service-btn-container button {
      min-width: 0;
    }
  }

  @media only screen and (max-width:990px) {
    .column-fourth {
      width: 100%;
    }

    .service-desc-container {
      min-height: 0;
    }

    #services-row, #contact-us-section {
      padding-left: 25px;
      padding-right: 25px;
    }

    .column-half {
      width: 100% !important;
      padding-right: 0 !important;
    }

    #services-row {
      padding-bottom: 0;
    }

    #contact-us-section {
      padding-bottom: 0;
      padding-top: 0;
    }

    .contact-form-img-container {
      margin-top: 20px;
      margin-bottom: 25px;
    }

  }

  @media only screen and (max-width:981px) {
    #hero-section .column-half {
      width: 100%;
      padding:0;
    }

    #hero-section .column-half img {
      width: 100%;
      margin-bottom:0;
      margin-top:0;
    }

    #hero-section .column-half p{
      padding-right:0;
    }

    #hero-section .column-half.last {
      width:100%;
      padding-top:25px;
    }

    #hero-section {
      padding:40px 25px;
      padding-bottom: 150px;
    }

    #hero-section h1 {
      font-size: 28px;
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width:875px) {

    #hero-section .column-whole {
      padding: 0px 0px 0px 0px;
      margin-left: 0;
    }
  }
`;
