import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { aboutUsLinks } from 'components/Layout/navigation';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import ArrowButton from 'components/Button/ArrowButton';
import Layout from 'components/Layout';
import TabNavigator from 'components/TabNavigator';
import { HeaderMedium } from 'components/typography';
import { MobileFilter } from 'components/BackgroundFilter';
import { WidthWrapper } from 'components/wrappers';
import { HeroSection } from 'components/wrappers';
import { BasicDiv } from 'components/wrappers';
import { TextDesc } from 'components/wrappers';
import fba from 'images/_fba_prep_forwarding2_.png';
import shipping from 'images/shipping2.png';
import fulfillment from 'images/fulfillment2.png';
import crowdfunding_fulfillment from 'images/dropshipping-shipkoo-icon.png';
import ServiceIncludes from 'components/Service/ServiceIncludes'
import ContactForm from 'components/pages/Contact/ContactForm'
import EcommerceBrandsSlider from 'components/pages/Services/EcommerceBrands'
import OurPartnersSlider from 'components/pages/Services/OurPartners'
import ScheduleConsultation from 'images/schedule-consultation.png'
import ContactFormSection from 'components/pages/Contact/ContactFormSection'

import {UnorderedList} from 'components/blog/SingleBlogStyle';

import { DetailContent, PageContainer, MainSection }  from 'components/Service/services_styled';

const ContentSmall = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
`;

const ContentSmallTitle = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:0px;
`;

const ContentSmallBg = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:25px;
  background: #FCF1DE;
  text-align: center;
  border-bottom:2px solid #000;
`;

const ContentSmallCenter = styled.p`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
  margin-bottom:10px;
  text-align: center;
`;

const ContentSmallSpan = styled.span`
  position: relative;
  color: #4D4D4D;
  font-size: 15px;
  font-weight: 400;
  line-height:1.5;
`;

const Clear  = styled.div`
  clear:both;
  padding:0 !important;
`;

const Row  = styled.div`
  max-width:1255px;
  margin-left:auto;
  margin-right:auto;

  .box-shadow {
    -webkit-box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3); 
    box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    padding: 20px 20px;
  }

  #calendly-form-container {
    margin-top: 30px;
  }

  #calendly-form-container img {
    margin-left: auto;
    margin-right: auto;
  }

  #calendly-form-container h2 {
    font-weight: 500;
    text-align: center;
    font-size: 25px;
    margin-top: 30px;
    line-height: 1.5;
  }

  #calendly-form-container a {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
  }

  #calendly-form-container .btn-outlined {
    width: 100%;
    height: 55px;
    border-radius: 10px;
    background: transparent;
    color: #00af85;
    font-size: 18px;
    border: 2px solid #00af85;
    margin-top: 25px;
    transition: 0.5s;
    margin-bottom: 20px;
  }

  #calendly-form-container .btn-outlined:hover {
    cursor: pointer;
    background: #00af85;
    color: #ffffff;
  }

  @media only screen and (max-width: 981px) {

    #calendly-form-container .box-shadow {
      padding: 20px 30px;
    }
  }

  @media only screen and (min-width: 982px) {
    
    #calendly-form-container h2 {
      font-size: 35px;
    }

    #calendly-form-container .btn-outlined {
      margin-top: 55px;
    }

    .box-shadow {
      padding: 30px 20px;
    }

    padding-top: 50px;
    padding-bottom: 50px; 
  }
`;

const HeroHeader = styled.h1`
  font-size: 45px;
  margin-bottom: 20px;
  font-weight: 500;
  color: #fff;
  text-align: center;
`;

const HeroText = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: #7f7f7f;
  line-height:1.8;
  color: #ffffff;
  text-align: center;
`;
const Breadcrumbs = styled.p`
<<<<<<< HEAD
<<<<<<< HEAD
=======
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
    padding: 0px 0px 25px 0px;
    width:100%;
    text-align:center;
    color:#ffff
<<<<<<< HEAD
=======
    padding: 25px 0px 25px 50px;
    width:100%
>>>>>>> bf7b6d65e (27-09-2022)
=======
>>>>>>> 7184fbe7c (28-09-22-technical-changes)
`;

const ServicesPage = () => {
  const data = useStaticQuery(graphql`
    query ServicesPageQuery {
      services_bg: file(relativePath: { eq: "services_hero_bg.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
      contact_us_img: file(relativePath: { eq: "contact-us-shipkoo.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `);
  const intl = useIntl()
  return (
    <Layout>
      <Helmet>
        {/* <title>Services | Shipkoo</title> */}
        <title>{intl.formatMessage({ id: "meta-title-services" })}</title>
        
        <link rel="alternate" href={intl.formatMessage({ id: "services-link" })} hreflang={intl.formatMessage({ id: "language" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "services-link-alt" })} hreflang={intl.formatMessage({ id: "language-alt" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "services-link-alt2" })} hreflang={intl.formatMessage({ id: "language-alt2" })} />
        <link rel="alternate" href={intl.formatMessage({ id: "services-link-alt3" })} hreflang={intl.formatMessage({ id: "language-alt3" })} />
        <meta name="description" content="" />
        <meta property="og:title" content={intl.formatMessage({ id: "meta-title-services" })} />
        <meta property="og:description" content="" />
        <meta name="twitter:title" content={intl.formatMessage({ id: "meta-title-services" })} />
        <meta name="twitter:description" content="" />
      </Helmet>
      <PageContainer>
        <MainSection>
          <div id="hero-section" className={intl.formatMessage({ id: "language" })}>
            <Img className="hero-bg" fluid={data.services_bg.childImageSharp.fluid} alt="all in one digital platform"/>
            <Row>
                <div className="column-whole">
                  <div className="content-container">
                    <Breadcrumbs className = "breadcrumb"><Link to="/">Home</Link> / {intl.formatMessage({ id: "menu-services" })}</Breadcrumbs>
                    <HeroHeader>{intl.formatMessage({ id: "services-hero-header" })}</HeroHeader>
                    <HeroText>{intl.formatMessage({ id: "services-hero-description" })}</HeroText>
                  </div>
                </div>
                <Clear></Clear>
            </Row>
          </div>
        </MainSection>
        <MainSection>
          <div id="services-row" className={intl.formatMessage({ id: "language" })} >
            <Row>
              <div className="column-fourth">
                <div className="service-container">
                  <img src={fba} alt="FBA Pref & Forwarding" />
                  <h3 className="service-title">
                    {intl.formatMessage({ id: "home-s2-column3-header" })}
                  </h3>
                  <div className="service-desc-container">
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column3-subheader" })}
                    </p>
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column3-text" })}
                    </p>
                  </div>
                  <div className="service-btn-container">
                    <Link to="/services/fba-prep-and-forwarding/">
                      <ArrowButton>{intl.formatMessage({ id: "more-info-button" })}</ArrowButton>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="column-fourth">
                <div className="service-container">
                  <img src={shipping} alt="Express Shipping" />
                  <h3 className="service-title">
                    {intl.formatMessage({ id: "home-s2-column1-header" })}
                  </h3>
                  <div className="service-desc-container">
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column1-subheader" })}
                    </p>
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column1-text" })}
                    </p>
                  </div>
                  <div className="service-btn-container">
                    <Link to="/services/express-shipping/">
                      <ArrowButton>{intl.formatMessage({ id: "more-info-button" })}</ArrowButton>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="column-fourth">
                <div className="service-container">
                  <img src={fulfillment} alt="Ecommerce Fulfillment" />
                  <h3 className="service-title">
                    {intl.formatMessage({ id: "home-s2-column2-header" })}
                  </h3>
                  <div className="service-desc-container">
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column2-subheader" })}
                    </p>
                    <p className="service-desc">
                      {intl.formatMessage({ id: "home-s2-column2-text" })}
                    </p>
                  </div>
                  <div className="service-btn-container">
                    <Link to="/services/ecommerce-fulfillment/">
                      <ArrowButton>{intl.formatMessage({ id: "more-info-button" })}</ArrowButton>
                    </Link>
                  </div>
                </div>
              </div>
              
              <div className="column-fourth last">
                <div className="service-container">
                  <img src={crowdfunding_fulfillment} alt="Crowfunding Fulfillment" />
                  <h3 className="service-title">
                    {intl.formatMessage({ id: "crowdfunding-fulfillment-col-title" })}
                  </h3>
                  <div className="service-desc-container">
                    <p className="service-desc">
                      {intl.formatMessage({ id: "crowdfunding-fulfillment-col-desc" })}
                    </p>
                    <p className="service-desc">
                      {intl.formatMessage({ id: "crowdfunding-fulfillment-col-hover-desc" })}
                    </p>
                  </div>
                  <div className="service-btn-container">
                    <Link to="/services/crowdfunding-fulfillment/">
                      <ArrowButton>{intl.formatMessage({ id: "more-info-button" })}</ArrowButton>
                    </Link>
                  </div>
                </div>
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </MainSection>
        <MainSection>
          <ServiceIncludes />
        </MainSection>
        <EcommerceBrandsSlider />
        <ContactFormSection />
        {/*<MainSection>
          <div id="contact-us-section">
            <Row>
              <div className="column-half">
                <Img fluid={data.contact_us_img.childImageSharp.fluid} alt="Shipkoo - Contact Us"/>
                <div id="calendly-form-container" className="contact-column">
                    <div className="box-shadow">
                      <img src={ScheduleConsultation} alt="Schedule a Constultation" />
                      <h2>
                        Schedule a 15 Minute Consultation
                      </h2>
                      <a href="https://calendly.com/yannickdevries/15-minute-consultation" target="_blank">
                        <button className="btn-outlined">
                          Schedule a Meeting
                        </button>
                      </a>
                    </div>
                  </div>
                
              </div>
              <div className="column-half last">
                <h3 className="contact-form-header">
                  {intl.formatMessage({ id: "c-contact-subheader" })}
                </h3>
                <p className="contact-form-description">
                  {intl.formatMessage({ id: "c-contact-description" })}
                </p>
                <ContactForm />
              </div>
              <Clear></Clear>
            </Row>
          </div>
        </MainSection>*/}
        <OurPartnersSlider />
      </PageContainer>
    </Layout>
  );
};

export default ServicesPage;
