import React from 'react';
import styled from 'styled-components';
import { useIntl } from "gatsby-plugin-intl";
import ContactForm from 'components/pages/Contact/ContactForm/ContactFormV2';
import ScheduleConsultation from 'images/schedule-consultation.png'

import { MainSection }  from 'components/pages/Contact/styledElements';

const Row  = styled.div`
  max-width:1200px;
  margin-right:auto;
  margin-left:auto;
  padding-top: 20px;
  padding-bottom: 20px;

  .forms-container {
    width: 100%;
  }

  #contact-form-container .contact-form-header {
    padding-left: 10px;
    padding-right: 10px;
  }

  .forms-container .contact-form-header{
    color: #000;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
  }

  #contact-form-container .contact-form-description {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
  }

  #contact-form-container form > div:last-child {
    margin-bottom: 10px;
  }

  .contact-column {
    padding: 20px;
  }

  #contact-form-container form input, #contact-form-container form textarea {
    border: 2px solid rgb(0,175,133);
    text-transform: capitalize;
    padding: 6px 15px;
    border-radius: 10px;
  }

  #contact-form-container form input[type=email], #contact-form-container form textarea {
    text-transform: none;
  }

  #contact-form-container form {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  .box-shadow {
    -webkit-box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    box-shadow: 0px 0px 8px 5px rgba(181,181,181,0.3);
    padding: 20px 20px;
  }

  #calendly-form-container img {
    margin-left: auto;
    margin-right: auto;
  }

  #calendly-form-container h2 {
    font-weight: 500;
    text-align: center;
    font-size: 25px;
    margin-top: 30px;
    line-height: 1.5;
    min-height: 164px;
  }

  #calendly-form-container a {
    padding-left: 10px;
    padding-right: 10px;
    display: block;
    margin-top: 25px;
  }

  #calendly-form-container .btn-outlined {
    width: 100%;
    height: 55px;
    border-radius: 10px;
    background: transparent;
    color: #00af85;
    font-size: 18px;
    border: 2px solid #00af85;
    transition: 0.5s;
    margin-bottom: 9px;
  }

  #calendly-form-container .btn-outlined:hover {
    cursor: pointer;
    background: #00af85;
    color: #ffffff;
  }

  form > div {
    margin-left: 0 !important;
  }

  #contact-form-container form button {
    margin-top: 37px !important;
  }

  #contact-form-container form {
      margin-bottom: 0 !important;
  }

  @media only screen and (max-width: 981px) {
    #contact-form-container form > div {
      margin-left: 0;
      margin-right: 0;
    }
    #calendly-form-container .box-shadow {
      padding: 20px 30px;
    }
  }

  @media only screen and (min-width: 982px) {
    .contact-column {
      display: inline-block;
      width: 50%;
      vertical-align: top;
    }

    #calendly-form-container h2 {
      font-size: 35px;
    }

    #calendly-form-container .box-shadow {
      padding-top: 80px;
    }

    #calendly-form-container a {
      margin-top: 79px;
    }

    .box-shadow {
      min-height: 479px;
      padding: 30px 20px;
    }

    .forms-container .contact-form-header {
      font-size: 36px;
      margin-bottom: 45px;
    }

    padding-top: 30px;
    padding-bottom: 70px;
  }
`;

const Contact2 = () => {
  const intl = useIntl()
  return (
    <MainSection>
        <Row>
            <div className="forms-container">
                <h3 className="contact-form-header">
                  {intl.formatMessage({ id: "c-contact-subheader" })}
                </h3>
                <div id="contact-form-container" className="contact-column">
                  <div className="box-shadow">
                    <p className="contact-form-description">
                    {intl.formatMessage({ id: "c-contact-description" })}
                    </p>
                    <ContactForm />
                  </div>
                </div>
                <div id="calendly-form-container" className="contact-column">
                <div className="box-shadow">
                    <img src={ScheduleConsultation} alt="Schedule a Constultation" />
                    <h2>
                    {intl.formatMessage({ id: "home-contact-title" })}
                    </h2>
                    <a href="https://calendly.com/customer-11/30min" target="_blank" rel="noreferrer">
                    <button className="btn-outlined">
                        {intl.formatMessage({ id: "c-contact-form-button" })}
                    </button>
                    </a>
                </div>
                </div>
            </div>
        </Row>
    </MainSection>
  );
};

export default Contact2;
